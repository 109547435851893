/*
 * @Descripttion: 基础模块 api
 * @version: 1.0.0
 * @Author: icon
 * @Date: 2022-06-14 13:59:10
 * @LastEditors: lijingtao 2528764665@qq.com
 * @LastEditTime: 2022-09-24 16:31:30
 */
import http from '../http'
import { AxiosPromise } from 'axios'
import { UploadFile } from '@/types'
export const MODULE_ROUTE = '/api-basics'

export * from './dept'
export * from './user'
export * from './menu'
export * from './role'
export * from './meta'
export * from './tenant'
export * from './banner'
export * from './store'
export * from './clearCache'
export * from './classRoom'
export * from './upgradeCardApprove'
export * from './approval'
export * from './video'
// 图片上传
export function getAliToken(): AxiosPromise<UploadFile> {
  return http({
    url: `${MODULE_ROUTE}/sys/file/token`,
    method: 'get',
  })
}
