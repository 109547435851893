/*
 * @Descripttion: pinia 主出口
 * @version: 1.0.0
 * @Author: icon
 * @Date: 2022-06-08 10:33:25
 * @LastEditors: icon
 * @LastEditTime: 2022-08-06 13:36:45
 */

import useAppStore from './modules/app'
import useUserStore from './modules/user'
import usePermissionStore from './modules/permission'
import useTagsViewStore from './modules/tagsView'
import useSettingStore from './modules/settings'
import useDictStore from './modules/dict'

const useStore = () => ({
  user: useUserStore(),
  permission: usePermissionStore(),
  app: useAppStore(),
  tagsView: useTagsViewStore(),
  setting: useSettingStore(),
  dict: useDictStore(),
})
export default useStore
