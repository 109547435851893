/*
 * @Descripttion: 配置文件
 * @version:
 * @Author: icon
 * @Date: 2022-06-22 16:34:20
 * @LastEditors: qingzhuo
 * @LastEditTime: 2023-11-20 09:53:05
 */
// 开发地址
// const testUrl = 'https://api.dangjiayoga.com' // 线上环境
const testUrl = 'https://tapi.dangjiayoga.com' // 测试环境
// const testUrl = 'http://192.168.0.17:31900' // dev
// const testUrl = 'http://192.168.0.129:9900' // 兵=乒+乓
// const testUrl = 'http://192.168.0.140:9900' // 志发
// const testUrl = 'http://192.168.0.22:9900' // yookien

// 接口地址
export const apiUrl = testUrl

// cos上传地址
export const cosUrl = 'https://cdn.fanyu.cn'

// 项目id
export const appChannelId = 5
// 0= "当伽云（APP）
// 1= "当伽云（微信小程序）
// 2= "当伽精英（微信小程序）
// 3="当伽云（抖音小程序）
// 4= "当伽云（H5）
// 5 = "当伽云（PC）
// 6= 学院培训

// 当前版本
export const version = '2.0.7'
