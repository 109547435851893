/*
 * @Descripttion: 元数据（字典）api
 * @version: 1.0.0
 * @Author: icon
 * @Date: 2022-06-16 14:27:56
 * @LastEditors: icon
 * @LastEditTime: 2022-08-06 11:20:19
 */
import http from '../http'
import { AxiosPromise } from 'axios'
import { MODULE_ROUTE } from './index'
import {
  MetaPageQueryParam,
  MetaPageResult,
  IdParam,
  MetaFormData,
  MetaContentItem,
  MetaContentFormData,
  Option,
  MetaContentAllResult,
} from '@/types'

// 获取所有元数据
export function getMetaContentAll(): AxiosPromise<MetaContentAllResult> {
  return http({
    url: `${MODULE_ROUTE}/sys/meta/content/all`,
    method: 'get',
  })
}

// 分页查询元数据
export function getMetaPage(data: MetaPageQueryParam): AxiosPromise<MetaPageResult> {
  return http({
    url: `${MODULE_ROUTE}/sys/meta/page`,
    method: 'post',
    data,
  })
}

// 新增or修改元数据
export function saveMeta(data: MetaFormData) {
  return http({
    url: `${MODULE_ROUTE}/sys/meta/save`,
    method: 'post',
    data,
  })
}

// 删除元数据
export function deleteMeta(params: IdParam) {
  return http({
    url: `${MODULE_ROUTE}/sys/meta/delete`,
    method: 'post',
    params,
  })
}

// 查询元数据项
export function getMetaContentList(params: IdParam): AxiosPromise<MetaContentItem[]> {
  return http({
    url: `${MODULE_ROUTE}/sys/meta/content/list`,
    method: 'get',
    params,
  })
}

// 新增or修改元数据项
export function saveMetaContent(data: MetaContentFormData) {
  return http({
    url: `${MODULE_ROUTE}/sys/meta/content/save`,
    method: 'post',
    data,
  })
}

// 删除元数据项
export function deleteMetaContent(params: IdParam) {
  return http({
    url: `${MODULE_ROUTE}/sys/meta/content/delete`,
    method: 'post',
    params,
  })
}

// 获取元数据项选择框
export function selectMetaContent(metaCode: string): AxiosPromise<Option[]> {
  return http({
    url: `${MODULE_ROUTE}/sys/meta/content/select`,
    method: 'get',
    params: {
      metaCode,
    },
  })
}
