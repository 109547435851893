/*
 * @Author: icon
 * @Date: 2022-06-08 10:34:36
 * @LastEditors: qingzhuo
 * @LastEditTime: 2023-05-15 11:18:38
 * @FilePath: /vue3-element-admin/src/api/http.ts
 * @Description: http request
 */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { getRefreshToken } from '@/api/auth'
import { ElMessage, ElMessageBox } from 'element-plus'
import { localStorage } from '@/utils/storage'
import useStore from '@/store' // pinia
import { apiUrl, appChannelId } from '@/config'

let baseURL = ''
if (process.env.NODE_ENV === 'development') {
  baseURL = import.meta.env.VITE_APP_BASE_API
  // baseURL = apiUrl
} else {
  // baseURL = `${import.meta.env.VITE_APP_BASE}:${import.meta.env.VITE_APP_PORT}`
  baseURL = `${import.meta.env.VITE_APP_BASE}`
}

// 创建 axios 实例
const service = axios.create({
  baseURL: baseURL,
  timeout: 50000,
  headers: { 'Content-Type': 'application/json;charset=utf-8' },
})

// 请求拦截器
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (!config.headers) {
      throw new Error(`Expected 'config' and 'config.headers' not to be undefined`)
    }
    if (config.url === '/api-auth/oauth/token') {
      config.headers.Authorization = 'Basic ZmFueXUtYWRtaW4td2ViOjEyMzQ1Ng=='
    } else {
      const { user } = useStore()
      if (user.token) {
        config.headers.Authorization = `${localStorage.get('tokenType')} ${localStorage.get('token')}`
      }
    }
    if (process.env.NODE_ENV === 'development') {
      config.headers['javaUrl'] = apiUrl
    }

    config.headers['referers'] = window.location.href || ''
    config.headers['app-channel-id'] = String(appChannelId)
    return config
  },
  (error) => {
    ElMessage({
      message: '后台服务异常',
      type: 'error',
    })
    return Promise.reject(error)
  }
)

let isRefreshing = false
let requests: any[] = []
// 响应拦截器
service.interceptors.response.use(
  (response: AxiosResponse) => {
    const { code, msg } = response.data
    // 105 token已过期或者需要刷新
    if (code === '200') {
      return response.data
    }
    ElMessage({
      message: msg || '系统出错',
      type: 'error',
    })
    if (code == '1010') {
      removeToken()
      window.location.href = '/' // 跳转到首页
    }
    if (code === '1001') {
      removeToken()
      window.location.href = '/login' // 跳转到登录
    }
    return Promise.reject(new Error(msg || 'Error'))
  },
  async (error) => {
    const { code, msg } = error.response.data
    const { user } = useStore()
    // return
    const config = error.config
    if (code === '1003') {
      if (user.refreshToken) {
        if (!isRefreshing) {
          isRefreshing = true
          return getRefreshToken({
            refresh_token: localStorage.get('refreshToken'),
            grant_type: 'refresh_token',
          })
            .then((res) => {
              localStorage.set('token', res.data.access_token)
              config.headers['Authorization'] = `${localStorage.get('tokenType')} ${localStorage.get('token')}`
              requests.forEach((item) => {
                item()
              })
              requests = []
              return service(config)
            })
            .finally(() => {
              isRefreshing = false
            })
        } else {
          return new Promise((resolve) => {
            requests.push(() => {
              config.headers['Authorization'] = `${localStorage.get('tokenType')} ${localStorage.get('token')}`
              resolve(service(config))
            })
          })
        }
      } else {
        removeToken()
      }
      return
    }
    ElMessage({
      message: msg || '系统出错',
      type: 'error',
    })
    if (code == '1010') {
      removeToken()
      window.location.href = '/' // 跳转到首页
    }
    if (code === '1001') {
      removeToken()
      window.location.href = '/login' // 跳转到登录
    } else if (code === '1002') {
      window.location.href = '/' // 跳转到首页
    }
    return Promise.reject(new Error(msg || 'Error'))
  }
)

function removeToken() {
  const { user } = useStore()
  user.resetToken()
  localStorage.clear() // 清除浏览器全部缓存
  window.location.href = '/' // 跳转登录页
  ElMessageBox.alert('当前页面已失效，请重新登录', '提示', {})
    .then(() => {})
    .catch(() => {})
}

// 导出 axios 实例
export default service
