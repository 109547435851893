/*
 * @Descripttion: 设置 pinia
 * @version: 1.0.0
 * @Author: icon
 * @Date: 2022-06-08 14:45:32
 * @LastEditors: icon
 * @LastEditTime: 2022-06-23 11:52:25
 */

import { defineStore } from 'pinia'
import { SettingState } from '@/types'
import defaultSettings from '../../settings'
import { localStorage } from '@/utils/storage'

const { showSettings, tagsView, keepAlive, fixedHeader, sidebarLogo } = defaultSettings
const el = document.documentElement

export const useSettingStore = defineStore({
  id: 'setting',
  state: (): SettingState => ({
    theme: '#165DFF' || getComputedStyle(el).getPropertyValue(`--el-color-primary`),
    showSettings: showSettings,
    tagsView: localStorage.get('tagsView') != null ? localStorage.get('tagsView') : tagsView,
    keepAlive: keepAlive,
    fixedHeader: fixedHeader,
    sidebarLogo: sidebarLogo,
  }),
  actions: {
    async changeSetting(payload: { key: string; value: any }) {
      const { key, value } = payload
      switch (key) {
        case 'theme':
          this.theme = value
          break
        case 'showSettings':
          this.showSettings = value
          break
        case 'fixedHeader':
          this.fixedHeader = value
          break
        case 'tagsView':
          this.tagsView = value
          localStorage.set('tagsView', value)
          break
        case 'sidebarLogo':
          this.sidebarLogo = value
          break
        default:
          break
      }
    },
  },
})

export default useSettingStore
