/*
 * @Descripttion: app pinia
 * @version: 1.0.0
 * @Author: icon
 * @Date: 2022-06-08 14:34:55
 * @LastEditors: icon
 * @LastEditTime: 2022-11-21 14:02:34
 */

import { AppState } from '@/types'
import { localStorage } from '@/utils/storage'
import { defineStore } from 'pinia'

const useAppStore = defineStore({
  id: 'app',
  state: (): AppState => ({
    device: 'desktop',
    sidebar: {
      opened: localStorage.get('sidebarStatus') ? !!+localStorage.get('sidebarStatus') : true,
      withoutAnimation: false,
    },
    size: localStorage.get('size') || 'default', // 布局大小
    cacheQuery: {
      data: {},
      path: '',
    },
  }),
  actions: {
    toggleSidebar() {
      this.sidebar.opened = !this.sidebar.opened
      this.sidebar.withoutAnimation = false
      if (this.sidebar.opened) {
        localStorage.set('sidebarStatus', 1)
      } else {
        localStorage.set('sidebarStatus', 0)
      }
    },
    closeSideBar(withoutAnimation: any) {
      localStorage.set('sidebarStatus', 0)
      this.sidebar.opened = false
      this.sidebar.withoutAnimation = withoutAnimation
    },
    toggleDevice(device: string) {
      this.device = device
    },
    setSize(size: string) {
      this.size = size
      localStorage.set('size', size)
    },
    setCacheQuery(data, path) {
      this.cacheQuery = {
        data,
        path,
      }
    },
  },
})

export default useAppStore
