/*
 * @Descripttion: 全局布局 type
 * @version: 1.0.0
 * @Author: icon
 * @Date: 2022-06-08 14:47:11
 * @LastEditors: icon
 * @LastEditTime: 2023-02-07 17:56:11
 */

interface DefaultSettings {
  title: string
  showSettings: boolean
  tagsView: boolean
  keepAlive: boolean
  fixedHeader: boolean
  sidebarLogo: boolean
  errorLog: string
}

const defaultSettings: DefaultSettings = {
  title: 'fanyuyun',
  showSettings: false,
  tagsView: true,
  keepAlive: true,
  fixedHeader: true,
  sidebarLogo: false,
  errorLog: 'production',
}

export default defaultSettings
