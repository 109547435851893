<!--
 * @Descripttion: app 主入口
 * @version: 1.0.0
 * @Author: icon
 * @Date: 2022-06-08 09:25:38
 * @LastEditors: qingzhuo
 * @LastEditTime: 2023-03-29 11:36:07
-->
<template>
  <el-config-provider :size="size">
    <router-view />
  </el-config-provider>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { ElConfigProvider } from 'element-plus'
import useStore from '@/store'
const { app } = useStore()
const size: any = computed(() => app.size)

function handleListenerError(eventErr) {
  if (eventErr.srcElement.localName == 'link' || eventErr.srcElement.localName == 'script') {
    // alert('因版本更新，页面需重新载入，请核对当次操作数据')

    window.location.reload()
  }

  eventErr.preventDefault()
}

window.addEventListener('error', handleListenerError, true)
</script>
