/*
 * @Descripttion: 用户状态模块
 * @version: 1.0.0
 * @Author: icon
 * @Date: 2022-06-08 10:30:08
 * @LastEditors: qingzhuo
 * @LastEditTime: 2023-04-28 14:27:53
 */

import { defineStore } from 'pinia'
import { UserState, LoginFormData, LoginCodeData } from '@/types' // 用户state的TypeScript类型声明，文件路径 src/types/store/user.d.ts
import { getUserMe } from '@/api/basics'
import { login, codeLogin, logout, getRefreshToken } from '@/api/auth'
import { resetRouter } from '@/router'
import { localStorage } from '@/utils/storage'

const useUserStore = defineStore({
  id: 'user',
  state: (): UserState => ({
    token: localStorage.get('token') || '',
    refreshToken: localStorage.get('refreshToken') || '',
    tokenType: localStorage.get('tokenType') || '',
    nickname: '',
    avatar: '',
    roles: [],
    perms: [],
    storeId: '',
    storeName: '',
    userId: '',
  }),
  actions: {
    // 清空 state
    async RESET_STATE() {
      this.$reset()
    },
    // 登录
    login(loginData: LoginFormData) {
      const { username, password } = loginData
      return new Promise<void>((resolve, reject) => {
        login({
          username: username.trim(),
          password: password,
          grant_type: 'password',
        })
          .then((response) => {
            const { access_token, token_type, refresh_token } = response.data
            localStorage.set('token', access_token)
            this.token = access_token
            localStorage.set('tokenType', token_type)
            this.tokenType = token_type
            localStorage.set('refreshToken', refresh_token)
            this.refreshToken = refresh_token
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 扫码登录
    codeLogin(codeData: LoginCodeData) {
      const { agentId, client_id, client_secret, grant_type, code } = codeData
      return new Promise<void>((resolve, reject) => {
        codeLogin({
          agentId: agentId,
          client_id: client_id,
          client_secret: client_secret,
          grant_type: grant_type,
          code: code,
        })
          .then((response) => {
            const { access_token, token_type, refresh_token } = response.data
            localStorage.set('token', access_token)
            this.token = access_token
            localStorage.set('tokenType', token_type)
            this.tokenType = token_type
            localStorage.set('refreshToken', refresh_token)
            this.refreshToken = refresh_token
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取用户信息
    getUserInfo() {
      return new Promise((resolve, reject) => {
        // 获取登录用户信息
        getUserMe().then(({ data }) => {
          const { nickname, avatar, roles, perms, storeId, storeName, userId } = data
          if (!roles || roles.length <= 0) {
            reject('getUserInfo: roles must be a non-null array!')
          }
          this.nickname = nickname
          this.avatar = avatar
          this.roles = roles
          this.perms = perms
          this.storeId = storeId == '0' ? '1' : storeId
          this.storeName = storeId == '0' ? '云上商城（自营）' : storeName
          this.userId = userId
          resolve(data)
        })
      })
    },
    // 按钮权限校验
    // <el-button v-if="user.hasPerm(['sys:user:add'])">新增</el-button>
    hasPerm(value: string[]) {
      const { roles, perms } = this
      if (roles.includes('ROOT')) {
        return true
      }
      if (value) {
        const hasPerm = perms.some((item) => {
          return value.includes(item)
        })
        if (hasPerm) {
          return true
        }
        return false
      }
      return false
    },
    // 注销
    logout() {
      return new Promise<void>((resolve, reject) => {
        logout()
          .then(async () => {
            await this.resetToken()
            resetRouter()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 清除 Token
    resetToken() {
      return new Promise<void>((resolve) => {
        localStorage.remove('token')
        localStorage.remove('refreshToken')
        localStorage.remove('tokenType')
        this.RESET_STATE()
        resolve()
      })
    },
    // // 重新获取token
    // getRefreshToken() {
    //   return new Promise<string>((resolve) => {
    //     getRefreshToken({
    //       refresh_token: this.refreshToken,
    //       grant_type: 'refresh_token',
    //     })
    //       .then((res) => {
    //         const { access_token } = res.data
    //         localStorage.set('token', access_token)
    //         this.token = access_token
    //         resolve(access_token)
    //       })
    //       .catch(() => {
    //         resolve('')
    //       })
    //   })
    // },
  },
})

export default useUserStore
