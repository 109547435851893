/*
 * @Descripttion:
 * @version:
 * @Author: icon
 * @Date: 2022-08-06 13:36:17
 * @LastEditors: icon
 * @LastEditTime: 2022-11-21 16:11:11
 */
import { DictState, MetaContentAllResult } from '@/types'
import { defineStore } from 'pinia'
import { getMetaContentAll, selectMetaContent } from '@/api/basics'

const useDictStore = defineStore({
  id: 'dict',
  state: (): DictState => ({
    dict: {} as MetaContentAllResult,
  }),
  actions: {
    // 通过字典key和对应的值查找对应的名称
    filter(key: string, value: string | number) {
      let name = ''
      const arr = this.dict[key] || []
      if (!arr.length) return name
      arr.find((item) => {
        if (item.value === value || String(item.value) === String(value)) {
          name = item.label
          return true
        }
      })
      return name
    },
    getList(key: string) {
      const arr = this.dict[key] || []
      return arr
    },
    async initDict() {
      const { data } = await getMetaContentAll()
      this.dict = data
      this.initDictLogistics()
    },
    async initDictLogistics() {
      const { data } = await selectMetaContent('logisticsCompany')
      this.dict['logisticsCompany'] = data
    },
  },
})

export default useDictStore
