/*
 * @Author: icon
 * @Date: 2022-06-14 13:52:23
 * @LastEditors: qingzhuo
 * @LastEditTime: 2022-07-19 16:49:30
 * @FilePath: /fanyu-web-pc-manage/src/api/auth/index.ts
 * @Description: 鉴权模块 api
 */
import http from '../http'
import { LoginFormData, LoginResponseData, LoginCodeData, RefreshFormData } from '@/types'
import { AxiosPromise } from 'axios'
// import useStore from '@/store'
const MODULE_ROUTE = '/api-auth'

// 登录
export function login(params: LoginFormData): AxiosPromise<LoginResponseData> {
  return http({
    url: `${MODULE_ROUTE}/oauth/token`,
    method: 'post',
    params,
  })
}

// 扫码登录
export function codeLogin(params: LoginCodeData): AxiosPromise<LoginResponseData> {
  return http({
    url: `${MODULE_ROUTE}/oauth/token`,
    method: 'post',
    params,
  })
}

// 注销
export function logout() {
  return http({
    url: `${MODULE_ROUTE}/oauth/logout`,
    method: 'get',
  })
}

// 重新获取token
export function getRefreshToken(params: RefreshFormData): AxiosPromise<LoginResponseData> {
  return http({
    url: `${MODULE_ROUTE}/oauth/token`,
    method: 'post',
    params,
  })
}
