/*
 * @Descripttion: 全局权限
 * @version: 1.0.0
 * @Author: icon
 * @Date: 2022-06-08 10:56:56
 * @LastEditors: icon
 * @LastEditTime: 2022-08-18 20:14:54
 */

import router from '@/router'
import { ElMessage } from 'element-plus'
import useStore from '@/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false }) // 进度环显示/隐藏

// 白名单路由
const whiteList = ['/login', '/auth-redirect', '/system/menu']

router.beforeEach(async (to, form, next) => {
  NProgress.start()
  const { user, permission, dict } = useStore()
  const hasToken = user.token
  // console.log(hasToken, 'hasToken')
  if (hasToken) {
    // 登录成功，跳转到首页
    if (to.path === '/login') {
      if (to.redirectedFrom != undefined) {
        next({ path: to.redirectedFrom.fullPath, query: to.redirectedFrom.query, replace: true })
      } else {
        next({ path: '/' })
      }
      NProgress.done()
    } else {
      const hasGetUserInfo = user.roles.length > 0
      if (hasGetUserInfo) {
        next()
      } else {
        try {
          await user.getUserInfo()
          await dict.initDict()
          // const roles = user.roles
          // 用户拥有权限的路由集合(accessRoutes)
          const accessRoutes: any = await permission.generateRoutes()
          // console.log(accessRoutes, 'accessRoutes')
          accessRoutes.forEach((route: any) => {
            router.addRoute(route)
          })
          if (to.path == '/404' && to.redirectedFrom != undefined) {
            next({ path: to.redirectedFrom.fullPath, query: to.query, replace: true })
          } else {
            next({ ...to, replace: true })
          }
          // next({ ...to, replace: true })
        } catch (error) {
          // 移除 token 并跳转登录页
          await user.resetToken()
          ElMessage.error((error as any) || 'Has Error')
          next(`/login?redirect=${to.fullPath}`)
          NProgress.done()
        }
      }
    }
  } else {
    // 未登录可以访问白名单页面(登录页面)
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      if (to.redirectedFrom != undefined) {
        next(`/login?redirect=${to.redirectedFrom.fullPath}`)
      } else {
        next(`/login?redirect=${to.fullPath}`)
      }
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
